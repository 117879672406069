import { EnvConst } from 'consts/commonConst'

// test環境
export const envConst: EnvConst = {
  ENVIRONMENT: 'test',
  DATASET_ID: 1047468921542269,
  FACILITY_NAME: '浪江工場',
  BACKEND_API_ROOT_URL:
    'https://ktyhidoh7k.execute-api.ap-northeast-1.amazonaws.com',
  CDF_PROJECT: 'ak-h2'
}
